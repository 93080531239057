import React from 'react'
import ServiceSidebar from './ServiceSidebar'
import projectservice2 from '../../assets/images/projects/projectservice2.png'
import projectservice from '../../assets/images/projects/projectservice.jpg'
// import projectservice3 from '../../assets/images/projects/projectservice3.jpg'
import Sales from '../../assets/images/services/sales.png'
import Marketing from '../../assets/images/services/marketingfile.png'



const ServiceDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={projectservice} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            {/* <span className="sub-title">AI & ML Development</span> */}
                            <h3>About Our Services</h3>
                            <p>Our dedicated, team is  available to assist with  ALL  your iT needs.  You can reach us out for further concerns and queries.</p>

                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={projectservice2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>We Build Software From Start To Finish</h3>
                                        <ul>
                                            <li>Requirements Engineering </li>
                                            <li> Design </li>
                                            <li>Integration </li>
                                            <li>Software Development </li>
                                            <li>Technical Validation </li>
                                            <li>Deployment  </li>
                                            <li>24/7 Support </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <p>Providing services from Requirements Engineering to deployment till support.</p>
                            <h3>Application Areas</h3>

                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Healthcare
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            {/* <i className="flaticon-hospital"></i> */}
                                            <img style={{width:'50px'}} src={Sales} />
                                        </div>
                                        Sales 
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-factory"></i>
                                        </div>                           
                                        Manufacturing
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                        <img style={{width:'50px'}} src={Marketing} />
                                        </div>
                                        Marketing
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Hospitality
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Logistics
                                    </div>
                                </div>
                            </div>

                            <h3>Technologies That We Use</h3>
                            <ul className="technologies-features">
                                <li><span>JavaScript</span></li>
                                <li><span>Python</span></li>
                                <li><span>MEAN</span></li>
                                <li><span>MERN</span></li>
                                <li><span>Flutter</span></li>
                                <li><span>Kubernetes</span></li>
                                <li><span>iconic</span></li>
                                <li><span>Vue</span></li>
                                <li><span>SQL</span></li>
                            </ul>
                            
                        </div>
                        

                    </div>

                    

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebar />
                    </div>
                   
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContent