import React from 'react'
import {Link} from 'gatsby'

const ServiceSidebar = () => {
    return (
        <div className="services-details-info">
            <ul className="services-list">
                <li>
                    <Link to="#" >
                        AI & ML Development
                    </Link>
                </li>
                <li>
                    <Link to="#">
                        Data Analytics
                    </Link>
                </li>
                <li>
                    <Link to="#">
                        Data Science
                    </Link>
                </li>
                <li>
                    <Link to="#">
                        Artificial Intelligence
                    </Link>
                </li>
                <li>
                    <Link to="#">
                        Data Visualization
                    </Link>
                </li>
            </ul>


            <ul className="services-list">
                <li>
                    <Link to="#" >
                    User experience (UX/UI)
                    </Link>
                </li>
                <li>
                    <Link to="#">
                            Mobile and Web Development
                    </Link>
                </li>
                <li>
                    <Link to="#">
                         QA and Testing 
                    </Link>
                </li>
                <li>
                    <Link to="#">
                    DevOps 
                    </Link>
                </li>
                <li>
                    <Link to="#">
                    Security 
                    </Link>
                </li>
            </ul>




            {/* <div className="download-file">
                <h3>Brochures</h3>

                <ul>
                    <li>
                        <Link to="#">
                            PDF Download <i className='bx bxs-file-pdf'></i>
                        </Link>
                    </li>
                    <li>
                        <Link to="#">
                            Services Details.txt <i className='bx bxs-file-txt'></i>
                        </Link>
                    </li>
                </ul>
            </div> */}

            {/* <div className="services-contact-info">
                <h3>Contact Info</h3>
                
                <ul>
                    <li>
                        <div className="icon">
                            <i className='bx bx-user-pin'></i>
                        </div>
                        <span>Phone:</span>
                        <a href="tel:+92-21-36722642">+92-21-36722642</a>
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-map'></i>
                        </div>
                        <span>Location:</span>
                        khayaban-e-Shaheen, Phase V, DHA
                        Karachi
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-envelope'></i>
                        </div>
                        <span>Email:</span>
                        <a href="mailto:info@zaavia.net">info@zaavia.net</a>
                    </li>
                </ul>
            </div> */}
        </div>
    )
}

export default ServiceSidebar